<template>
    <div class="membership">
        <div class="logo">
            <img src="../../assets/ikotun_logo_2.png"/>
        </div>
        <div class="main">
            <div class="form" v-if="c == 'login'">
                <div class="title">Welcome Back</div>
                <div class="small">Sign in to continue</div>
                <div class="input">
                    <div class="label">Username</div>
                    <input type="text" placeholder="Enter your username" v-model="username">
                    <div class="err" v-if="u">Username not found</div>
                </div>
                <div class="input">
                    <div class="label">Password</div>
                    <input type="password" placeholder="Enter your password" v-model="password">
                    <div class="err" v-if="p">Incorrect Password</div>
                </div>

                <loading :height="'43px'" v-if="l_loading"/>
                <div class="btn" v-else @click="login()">Login</div>

                <div class="forgot">Forgot Password?</div>
            </div>

            <div class="form" v-if="c == 'signup'">
                <div class="title">Sign up</div>
                <div class="small">Fill in your details below</div>
                <div class="input">
                    <div class="label">Email</div>
                    <input type="text" placeholder="Enter your email" v-model="email">
                    <div class="err" v-if="e">This email has already been registered</div>
                </div>
                <div class="input">
                    <div class="label">Username</div>
                    <input type="text" placeholder="Enter your username" v-model="user">
                    <div class="err" v-if="ur">This username has been taken</div>
                </div>
                <div class="input">
                    <div class="label">Password</div>
                    <input :type="input_type" placeholder="Enter your password" v-model="pass">
                    <div class="click" 
                        @click="input_type == 'password' ? input_type = 'text' : input_type = 'password'">
                        <div v-if="input_type == 'password'">Show</div>
                        <div v-if="input_type == 'text'">Hide</div>
                    </div>
                </div>

                <loading :height="'43px'" v-if="s_loading"/>
                <div class="btn" v-else @click="signup()">Sign up</div>
                <div class="text">
                    By signing up, you agree to Ikotun Connect 
                    <router-link to="/terms">
                        <b>Terms and Conditions</b>
                    </router-link>
                </div>
            </div>
        </div>

        
        <div class="menu">
            <div class="item" :class="[c == 'login' ? 'a' : '']" @click="change('login')">
                <!-- <login :size="'20px'" :style="{'heigth': '20px'}"/> -->
                Login
            </div>
            <div class="item" :class="[c == 'signup' ? 'a' : '']" @click="change('signup')">
                <!-- <signup :size="'20px'" :style="{'heigth': '20px'}"/> -->
                Sign up
            </div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

// import login from '../../icons/login.vue'
// import signup from '../../icons/signup.vue'

import loading from '../../components/loading.vue'

export default {
    components: {
        // login, signup,
        loading
    },
    data() {
        return {
            loading: false,
            username: '',
            password: '',
            u: false,
            p: false,
            l_loading: false,

            user: '',
            pass: '',
            email: '',
            input_type: 'password',
            e: false,
            ur: false,
            s_loading: false,
            c: 'login'
        }
    },
    watch: {
        username() {
            this.username = this.username.split(' ').join('').toLowerCase()
        },
        user() {
            this.user = this.user.split(' ').join('').toLowerCase()
        },
    },
    methods: {
        change(to) {
            if (this.loading) { return }
            this.c = to
        },
        login() {
            this.u = false
            this.p = false
            
            if (this.username == '' || this.password == '') {
                this.empty(); return
            }

            this.loading = true
            this.l_loading = true

            let form = new FormData()
            form.append('username', this.username)
            form.append('password', this.password)

            this.$http.post('/login', form)
            .then(data => {
                let r = data.data

                if (r.success) {

                    db.collection('auth').add({
                        id: 1,
                        created: r.created,
                        verified: r.verified,
                        firstname: r.firstname,
                        lastname: r.lastname,
                        gender: r.gender,
                        email: r.email,
                        dp: r.dp,
                        cover: r.cover,
                        email_verified: r.email_verified,
                        username: this.username,
                        location: r.location,
                        key: r.session,
                        
                        posts: r.posts,
                        comments: r.comments,
                        followers: r.followers,
                        followings: r.followings
                    }).then( () => {
                        this.loggedin()
                        this.$root.$data.login = true
                        this.$root.$data.created = r.created
                        this.$root.$data.verified = r.verified
                        this.$root.$data.firstname = r.firstname
                        this.$root.$data.lastname = r.lastname
                        this.$root.$data.gender = r.gender
                        this.$root.$data.email = r.email
                        this.$root.$data.username = this.username
                        this.$root.$data.location = r.location
                        this.$root.$data.dp = r.dp
                        this.$root.$data.cover = r.cover

                        this.$root.$data.posts = r.posts
                        this.$root.$data.comments = r.comments
                        this.$root.$data.followers = r.followers
                        this.$root.$data.followings = r.followings

                        this.$http.defaults.headers.common['Auth'] = r.session
                        
                        this.socket_auth(r.session)
                        
                        if (r.email_verified) {
                            this.$router.push('/home')
                        } else {
                            this.$router.push('/verify')
                        }
                    })
                }else {
                    if (r.username) { this.u = true }
                    if (r.password) { this.p = true }
                }
                
                this.loading = false
                this.l_loading = false
            })

        },
        signup() {
            console.log('www');
            this.e = false
            this.ur = false

            if (this.user == '' || this.pass == '' || this.email == '') {
                this.empty(); return
            }

            this.loading = true
            this.s_loading = true

            let form = new FormData()
            form.append('username', this.user)
            form.append('password', this.pass)
            form.append('email', this.email)
            form.append('firstname', '')
            form.append('lastname', '')

            this.$http.post('/signup', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    
                    db.collection('auth').add({
                        id: 1,
                        firstname: '',
                        lastname: '',
                        email: this.email,
                        verified: false,
                        username: this.user,
                        key: r.session
                    }).then( () => {
                        this.signedup()
                        this.$root.$data.login = true
                        this.$root.$data.email = this.email
                        this.$root.$data.username = this.user

                        this.$http.defaults.headers.common['Auth'] = r.session

                        this.socket_auth(r.session)
                        
                        if (r.email_verified) {
                            this.$router.push('/home')
                        } else {
                            this.$router.push('/verify')
                        }
                    })
                } else {
                    if (r.invalid) {
                        this.invalid()
                    }
                    if (r.username) {
                        this.ur = true
                    }
                    if (r.email) {
                        this.e = true
                    }
                }
                
                this.loading = false
                this.s_loading = false
            })
        },
        socket_auth(key) {
            
            let player_id = ''

            if (window.Android) {
                player_id = window.Android.get_player_id()
            }
            
            this.$socket.emit('authenticate', {
                'username': this.$root.$data.username,
                'Auth': key,
                'player_id': player_id,
                'app_version': this.$root.$data.app_version
            })

            this.$http.post('/chats')
                .then(data => {
                    let r = data.data
                    if (r.success) {
                        for(let x of r.chats) {
                            let chat = {
                                'chat_id': x.chat_id,
                                'date': x.created,
                                'updated_at': new Date(x.updated_at).getTime(),
                                'initiator': x.initiator,
                                'user1': x.user1,
                                'user2': x.user2,
                                'is_approved': x.is_approved,
                                'declined': x.declined,
                                'declined_at': '0000-00-00',
                                'count': x.count,
                                'user': x.initiator == this.$root.$data.username ? x.user2 : x.user1,
                                'new_messages': 0
                            }
                            
                            db.collection('chats').add(chat)
                            
                            db.collection('chat_users').add(x.chat_user)
                            
                            db.collection('users').add(x.user_details)

                            this.fetch_chat_messages(x.chat_id)
                        }
                    }
                })

        },
        fetch_chat_messages(chat_id) {

            let form = new FormData()
            form.append('chat_id', chat_id)

            this.$http.post('/chat/all_message', form)
            .then(data => {
                let r = data.data
                if (r.success) {

                    for(let msg of r.messages) {
                    
                        let message = {
                            'date': msg.date,
                            'chat_id': msg.chat_id,
                            'count': msg.count,
                            'type': msg.type,
                            'value': msg.value,
                            'text': msg.text,
                            'sender': msg.sender,
                            'receiver': msg.receiver,
                            'message_id': msg.message_id,
                            'sent': true,
                            'delivered': msg.delivered,
                            'delivered_date': msg.delivered_date,
                            'seen': msg.seen,
                            'seen_date': msg.seen_date,
                            'time': new Date(msg.date).getTime(),
                            'notify': msg.notify,
                            'meta': msg.meta
                        }

                        db.collection(message.chat_id).add(message).then(() => {
                            if (!msg.delivered) {

                                this.$socket.emit('message_delivered', {
                                    'message_id': message.message_id,
                                    'chat_id': message.chat_id
                                })

                            }
                        })
                    }
                }
            })

        }
    },
    notifications: {
        empty: {
            'type': 'warn',
            'title': 'Empty',
            'message': 'Some fields are empty'
        },
        invalid: {
            'type': 'warn',
            'title': 'Invalid',
            'message': 'The email you entered is invalid'
        },
        loggedin: {
            type: 'success',
            title: 'Success',
            message: 'You are now logged in'
        },
        signedup: {
            type: 'success',
            title: 'Success',
            message: 'Your account has been created'
        }
    }
}
</script>

<style scoped>
    .c {
        color: #8e4899;
    }

    .membership {
        /* background-image:url('../../assets/svg/ikotun_bg.svg'); */
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        display: grid;
        grid-template-rows: max-content auto max-content;
        height: 100%;
        background-color: white;
    }

    .logo {
        /* position: absolute; */
        /* top: -120px; */
        /* left: calc(50% - 100px); */
        /* background-color: rgba(0, 0, 0, 0.5); */
        /* width: 200px; */
        /* height: 200px; */
        padding: 30px;
        box-sizing: border-box;
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .logo img {
        width: 100px;
    }

    .main {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        box-sizing: border-box;
        overflow: auto;
        /* height: 100vh; */
    }

    .form {
        width: 100%;
        /* border-radius: 20px; */
        /* padding: 30px; */
        box-sizing: border-box;
        /* background-color: rgba(0, 0, 0, 0.5); */
        /* color: white; */
    }
    .title {
        font-weight: 800;
        font-size: 25px;
    }
    .small {
        margin-bottom: 30px;
        font-size: 14px;
        color: gray;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        font-size: 14px;
        font-weight: 600;
        padding: 0px 5px;
        background-color: white;
    }
    input {
        border: 1px solid #ddd;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
    }
    .err {
        font-size: 10px;
        font-weight: bold;
        color: tomato;
        position: absolute;
        bottom: 3px;
        right: 5px;
    }
    .click {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 14px;
        font-weight: 500;
    }
    .btn {
        background-color: var(--main);
        padding: 12px;
        text-align: center;
        border-radius: 5px;
        font-weight: 500;
        color: white;
    }

    .forgot {
        text-align: center;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
    }
    
    .text {
        margin-top: 20px;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
    }

    .menu {
        display: grid;
        grid-template-columns: 50% 50%;
        border-top: 1px solid transparent;
        /* border-top-right-radius: 20px; */
        /* border-top-left-radius: 20px; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px -7px 29px 0px;
        background-color: white;
        overflow: hidden;
        /* position: absolute; */
        /* bottom: 0px; */
        width: 100%;
    }
    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 15px;
        font-weight: 600;
        border-top: 2px solid transparent;
        box-sizing: border-box;
        font-size: 14px;
    }
    .a {
        border-top-color: var(--main);
        color: var(--main);
        fill: var(--main);
        /* background-color: var(--trans); */
    }
</style>